import { computed, getCurrentInstance } from '@vue/composition-api'

export default function reactiveNavigation() {
  const { setupContext } = getCurrentInstance()
  const { proxy } = getCurrentInstance()
  const { root } = setupContext
  const route = computed(() => proxy.$route.name)
  const store = root.$store
  const userData = computed(() => JSON.parse(localStorage.getItem('userData')))
  const project = computed(() => store.getters['projects/project'])
  const supplier = computed(() => store.getters['suppliers/supplier'])
  const customerId = computed(() => store.getters['customers/selectedCustomerResourceId'])
  // const customerId = computed(() => store.state.customers.selectedCustomerResourceId)

  const adminNavigation = computed(() => [
    {
      title: 'providers',
      route: 'suppliers',
      icon: 'HomeIcon',
    },
    {
      title: 'ProductCatalogs',
      icon: 'LayersIcon',
      children: [
        {
          title: 'products',
          route: 'productsCatalogs',
        },
        {
          title: 'categories',
          route: 'categories',
        },
      ],
    },
    {
      title: 'customers',
      route: 'customers',
      icon: 'UserIcon',
    },
    {
      title: 'users',
      route: 'users',
      icon: 'UsersIcon',
    },
    {
      title: 'purchaseOrders',
      route: 'payments',
      icon: 'CreditCardIcon',
    },
    {
      title: 'settings',
      route: 'settings',
      icon: 'SettingsIcon',
    },
    {
      title: 'invoices',
      icon: 'LayersIcon',
      children: [
        {
          title: 'customer',
          route: 'customerInvoices',
        },
        {
          title: 'suppliers',
          route: 'supplierInvoices',
        },
      ],
    },
  ])

  const adminNavigationLikeCustomer = computed(() => {
    const nav = [
      {
        title: 'customers',
        route: 'customers',
        icon: 'ArrowLeftIcon',
      },
      {
        title: 'projects',
        custom: customerId.value,
        route: {
          name: 'projects',
          params: {
            customerId: customerId.value,
          },
        },
        icon: 'HomeIcon',
      },
      {
        title: 'users',
        route: {
          name: 'members',
          params: {
            userId: customerId.value,
          },
        },
        icon: 'UsersIcon',
      },
    ]
    return nav
  })

  const adminNavigationLikeCustomerProject = computed(() => {
    const nav = [
      {
        title: 'customers',
        route: 'customers',
        icon: 'UserIcon',
      },
      {
        title: 'dashboard',
        route: {
          name: 'reports',
          params: {
            projectId: project.value.id,
          },
        },
        icon: 'ClipboardIcon',
      },
      {
        title: 'quotes',
        route: {
          name: 'quotes',
          params: {
            projectId: project.value.id,
          },
        },
        icon: 'HomeIcon',
      },
      {
        title: 'incomes',
        route: {
          name: 'incomes',
          params: {
            projectId: project.value.id,
          },
        },
        icon: 'CheckCircleIcon',
      },
      {
        title: 'expenses',
        route: {
          name: 'allExpenses',
          params: {
            projectId: project.value.id,
          },
        },
        icon: 'SendIcon',
      },
      // {
      //   title: 'expenses',
      //   icon: 'SendIcon',
      //   children: [
      //     {
      //       title: 'all',
      //       route: {
      //         name: 'allExpenses',
      //         params: {
      //           projectId: project.value.id,
      //         },
      //       },
      //     },
      //     {
      //       title: 'byProduct',
      //       route: {
      //         name: 'expenses',
      //         params: {
      //           projectId: project.value.id,
      //         },
      //       },
      //     },
      //   ],
      // },
      {
        title: 'groupedExpenses',
        route: {
          name: 'groupedExpenses',
          params: {
            projectId: project.value.id,
          },
        },
        icon: 'MinimizeIcon',
      },
    ]
    if (!root.$helpers.validZone(project.value.address?.state, project.value.address?.formatted_address)) {
      const index = nav.findIndex(i => i.title === 'quotes')
      if (index > 0) {
        nav.splice(index, 1)
      }
    }
    return nav
  })

  const supplierAdminNavigation = computed(() => [
    {
      title: 'branches',
      route: {
        name: 'branches',
        params: {
          id: userData.value.scoped_roles[0]?.role_resource_id,
        },
      },
      icon: 'HomeIcon',
    },
    {
      title: 'products',
      icon: 'LayersIcon',
      route: {
        name: 'supplierProductsCatalogs',
        params: {
          type: 'proveedor',
          id: userData.value.scoped_roles[0]?.role_resource_id,
        },
      },
    },
  ])

  const auctioneerNavigation = computed(() => [
    {
      title: 'biddings',
      route: {
        name: 'supplierQuotes',
        params: {
          id: userData.value.scoped_roles[0]?.role_resource_id,
        },
      },
      icon: 'HomeIcon',
    },
    {
      title: 'sells',
      route: {
        name: 'supplierSells',
        params: {
          id: userData.value.scoped_roles[0]?.role_resource_id,
        },
      },
      icon: 'CreditCardIcon',
    },
    {
      title: 'settings',
      route: {
        name: 'branch',
        params: {
          branchId: userData.value.scoped_roles[0]?.role_resource_id,
          supplierId: userData.value.scoped_roles[0]?.role_resource_scoped_id,
        },
      },
      icon: 'SettingsIcon',
    },
  ])

  const clientNavigation = computed(() => [
    {
      title: 'myProjects',
      route: {
        name: 'projects',
        params: {
          customerId: userData.value.scoped_roles[0]?.role_resource_id,
        },
      },
      icon: 'HomeIcon',
    },
    {
      title: 'users',
      route: {
        name: 'members',
        params: {
          userId: userData.value.id,
        },
      },
      icon: 'UsersIcon',
    },
    {
      title: 'editAccount',
      route: {
        name: 'editCustomer',
        params: {
          customerId: userData.value.id,
        },
      },
      icon: 'SettingsIcon',
    },
  ])

  const clientProjectNavigation = computed(() => {
    const nav = [
      {
        title: 'myProjects',
        route: {
          name: 'projects',
          params: {
            customerId: userData.value.scoped_roles[0]?.role_resource_id,
          },
        },
        icon: 'HomeIcon',
      },
      // {
      //   title: 'dashboard',
      //   route: {
      //     name: 'reports',
      //     params: {
      //       projectId: project.value.id,
      //     },
      //   },
      //   icon: 'ClipboardIcon',
      // },
      {
        title: 'quotes',
        route: {
          name: 'quotes',
          params: {
            projectId: project.value.id,
          },
        },
        icon: 'HomeIcon',
      },
      // {
      //   title: 'incomes',
      //   route: {
      //     name: 'incomes',
      //     params: {
      //       projectId: project.value.id,
      //     },
      //   },
      //   icon: 'CheckCircleIcon',
      // },
      // {
      //   title: 'expenses',
      //   route: {
      //     name: 'allExpenses',
      //     params: {
      //       projectId: project.value.id,
      //     },
      //   },
      //   icon: 'SendIcon',
      // },
      // {
      //   title: 'expenses',
      //   icon: 'SendIcon',
      //   children: [
      //     {
      //       title: 'all',
      //       route: {
      //         name: 'allExpenses',
      //         params: {
      //           projectId: project.value.id,
      //         },
      //       },
      //     },
      //     {
      //       title: 'byProduct',
      //       route: {
      //         name: 'expenses',
      //         params: {
      //           projectId: project.value.id,
      //         },
      //       },
      //     },
      //   ],
      // },
      {
        title: 'groupedExpenses',
        route: {
          name: 'groupedExpenses',
          params: {
            projectId: project.value.id,
          },
        },
        icon: 'MinimizeIcon',
      },
    ]
    if (!root.$helpers.validZone(project.value.address?.state, project.value.address?.formatted_address)) {
      const index = nav.findIndex(i => i.title === 'quotes')
      if (index > 0) {
        nav.splice(index, 1)
      }
    }

    return nav
  })

  const returnNavigationForUser = computed(() => {
    if (userData.value.role_name === 'admin') {
      if (
        [
          'reports',
          'quotes',
          'incomes',
          'expenses',
          'groupedExpenses',
          'allExpenses',
        ].includes(route.value)
      ) {
        return adminNavigationLikeCustomerProject.value
      }
      if (
        [
          'projects', 'members',
        ].includes(route.value)
      ) {
        return adminNavigationLikeCustomer.value
      }
      return adminNavigation.value
    }
    if (userData.value.role_name === 'customer') {
      if (
        ['projects', 'editCustomer', 'members', 'new-member'].includes(
          route.value,
        )
      ) {
        return clientNavigation.value
      }
      return clientProjectNavigation.value
    }
    if (
      userData.value.role_name === 'supplier_admin'
      && supplier.value.approved_status === 'approved'
    ) {
      return supplierAdminNavigation.value
    }
    if (userData.value.role_name === 'auctioneer') {
      return auctioneerNavigation.value
    }
    return []
  })

  return {
    adminNavigation,
    adminNavigationLikeCustomer,
    adminNavigationLikeCustomerProject,
    supplierAdminNavigation,
    auctioneerNavigation,
    clientNavigation,
    clientProjectNavigation,
    returnNavigationForUser,
    route,
    project,
  }
}
