<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow custom-bg-menu noprint"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      'menu-dark',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded custom-bg-menu">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto ml-auto text-center">
            <span
              class="navbar-brand"
              to="/"
            >
              <div class="w-100">
                <b-img
                  width="206px"
                  height="40px"
                  rounded
                  :src="
                    companyLogo ||
                      require('@/assets/images/yoconstruyo/logo_yocons-01.png')
                  "
                  alt=""
                />
              </div>
              <!-- <h2 class="brand-text text-white">
                {{ appName }}
              </h2> -->
            </span>
          </li>

          <!-- Toggler Button -->
          <!-- <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                color="#fff"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                color="#fff"
                @click="toggleCollapsed"
              />
            </b-link>
          </li> -->
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{ 'd-block': shallShadowBottom }"
      class="shadow-bottom"
    />
    <project-selector
      v-if="validRoute"
      :size="'md'"
      :extra-class="'bg-primary text-light'"
      class="mx-1 mt-1"
    />
    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area custom-bg-menu"
      tagname="ul"
      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
        }
      "
    >
      <vertical-nav-menu-items
        v-if="!loading"
        :key="counter"
        :items="returnNavigationForUser"
        class="navigation navigation-main custom-bg-menu"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
    <b-row
      class="nav-footer text-center align-items-end w-100 mx-0"
    >
      <b-col
        v-if="currentUser.role_name !== 'admin'"
        sm="12"
        class="px-1 w-100"
      >
        <a
          class="btn d-flex justify-content-center align-items-center btn-success btn-lg btn-block"
          block
          target="_blank"
          href="https://wa.me/5218120166240?text=Me%20gustaría%20conocer%20mas%20de%20Yoconstruyo"
        >
          <b-img
            :src="require('@/assets/images/icons/whatsapp.svg')"
            width="20px"
            style="margin-left: 5px; opacity: 0.5"
          />
          <span class="ml-2">
            {{ $t('quoteHere') }}
          </span>
        </a>
      </b-col>
      <b-col
        v-if="!!companyLogo"
        class="px-0"
      >
        <b-img
          width="206px"
          height="40px"
          rounded
          :src="require('@/assets/images/yoconstruyo/logo_yocons-01.png')"
          alt=""
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BImg } from 'bootstrap-vue'
import {
  provide, computed, ref, watch,
} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import { mapGetters } from 'vuex'
import navMenuItems from '@/navigation/vertical'
import reactiveNavigation from '@/navigation/vertical/reactiveNavigation'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import projectSelector from '@/components/projects/ProjectSelector.vue'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BImg,
    projectSelector,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    const {
      adminNavigation,
      adminNavigationLikeCustomer,
      adminNavigationLikeCustomerProject,
      supplierAdminNavigation,
      auctioneerNavigation,
      clientNavigation,
      clientProjectNavigation,
      returnNavigationForUser,
      route,
      project,
    } = reactiveNavigation()

    const counter = ref(0)

    watch(
      () => project.value,
      () => {
        counter.value += 1
      },
      { deep: true },
    )

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,

      // Navigation
      adminNavigation,
      adminNavigationLikeCustomer,
      adminNavigationLikeCustomerProject,
      supplierAdminNavigation,
      auctioneerNavigation,
      clientNavigation,
      clientProjectNavigation,
      returnNavigationForUser,
      route,
      project,
      counter,
    }
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    // ...mapGetters('projects', ['project']),
    ...mapGetters('suppliers', ['supplier']),
    ...mapGetters('auth', ['currentUser']),

    companyLogo() {
      if (this.currentUser.company_information?.logo?.includes('default')) {
        return false
      }
      return this.currentUser.company_information?.logo
    },

    validRoute() {
      if (this.currentUser.role_name === 'admin') return false
      if (this.$route.name === 'quotes') return true
      if (this.$route.name === 'groupedExpenses') return true
      if (this.$route.name === 'expenses') return true
      if (this.$route.name === 'allExpenses') return true
      if (this.$route.name === 'incomes') return true
      if (this.$route.name === 'incomesVsExpenses') return true
      if (this.$route.name === 'expensesReport') return true
      if (this.$route.name === 'reports') return true
      return false
    },
  },

  // watch: {
  //   project: {
  //     handler() {
  //       this.loading = true
  //       setTimeout(() => {
  //         this.loading = false
  //       }, 1)
  //     },
  //     deep: true,
  //   },
  // },
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
.custom-bg-menu {
  background-color: var(--secondary-custom) !important;
}
.nav-footer {
  position: absolute;
  bottom: 25px;
}
@media print {
  .noprint {
    display: none;
  }
}
</style>
