export default [
  {
    title: 'providers',
    route: 'suppliers',
    icon: 'HomeIcon',
  },
  {
    title: 'ProductCatalogs',
    icon: 'LayersIcon',
    children: [
      {
        title: 'products',
        route: 'productsCatalogs',
      },
      {
        title: 'categories',
        route: 'categories',
      },
    ],
  },
  {
    title: 'customers',
    route: 'customers',
    icon: 'UserIcon',
  },
  {
    title: 'users',
    route: 'users',
    icon: 'UsersIcon',
  },
  // {
  //   title: 'Vistas de cliente',
  //   icon: 'CalendarIcon',
  //   children: [
  //     {
  //       title: 'prequote',
  //       route: 'prequote',
  //     },
  //     {
  //       title: 'quote',
  //       route: 'clientQuote',
  //     },
  //     {
  //       title: 'checkout',
  //       route: 'checkout',
  //     },
  //     {
  //       title: 'bankAccountData',
  //       route: 'bankAccountData',
  //     },
  //     {
  //       title: 'income',
  //       route: 'income',
  //     },
  //     {
  //       title: 'myQuotes',
  //       route: 'myQuotes',
  //     },
  //     {
  //       title: 'myProjects',
  //       route: 'myProjects',
  //     },
  //     {
  //       title: 'expenses',
  //       route: 'expenses',
  //     },
  //     {
  //       title: 'supplies',
  //       route: 'supplies',
  //     },
  //     {
  //       title: 'shippings',
  //       route: 'shippings',
  //     },
  //   ],
  // },
]
