var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow custom-bg-menu noprint",class:[
    {
      expanded:
        !_vm.isVerticalMenuCollapsed ||
        (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
    },
    'menu-dark' ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"navbar-header expanded custom-bg-menu"},[_vm._t("header",function(){return [_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mr-auto ml-auto text-center"},[_c('span',{staticClass:"navbar-brand",attrs:{"to":"/"}},[_c('div',{staticClass:"w-100"},[_c('b-img',{attrs:{"width":"206px","height":"40px","rounded":"","src":_vm.companyLogo ||
                    require('@/assets/images/yoconstruyo/logo_yocons-01.png'),"alt":""}})],1)])])])]},{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('div',{staticClass:"shadow-bottom",class:{ 'd-block': _vm.shallShadowBottom }}),(_vm.validRoute)?_c('project-selector',{staticClass:"mx-1 mt-1",attrs:{"size":'md',"extra-class":'bg-primary text-light'}}):_vm._e(),_c('vue-perfect-scrollbar',{staticClass:"main-menu-content scroll-area custom-bg-menu",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
        _vm.shallShadowBottom = evt.srcElement.scrollTop > 0
      }}},[(!_vm.loading)?_c('vertical-nav-menu-items',{key:_vm.counter,staticClass:"navigation navigation-main custom-bg-menu",attrs:{"items":_vm.returnNavigationForUser}}):_vm._e()],1),_c('b-row',{staticClass:"nav-footer text-center align-items-end w-100 mx-0"},[(_vm.currentUser.role_name !== 'admin')?_c('b-col',{staticClass:"px-1 w-100",attrs:{"sm":"12"}},[_c('a',{staticClass:"btn d-flex justify-content-center align-items-center btn-success btn-lg btn-block",attrs:{"block":"","target":"_blank","href":"https://wa.me/5218120166240?text=Me%20gustaría%20conocer%20mas%20de%20Yoconstruyo"}},[_c('b-img',{staticStyle:{"margin-left":"5px","opacity":"0.5"},attrs:{"src":require('@/assets/images/icons/whatsapp.svg'),"width":"20px"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('quoteHere'))+" ")])],1)]):_vm._e(),(!!_vm.companyLogo)?_c('b-col',{staticClass:"px-0"},[_c('b-img',{attrs:{"width":"206px","height":"40px","rounded":"","src":require('@/assets/images/yoconstruyo/logo_yocons-01.png'),"alt":""}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }