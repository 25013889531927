<template>
  <div>
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-form-select
        v-model="projectId"
        class="border-primary bg-custom-grey"
        :class="extraClass"
        :size="size"
        @change="changeProject($event)"
      >
        <b-form-select-option
          v-for="project in projects"
          :key="project.id"
          :value="project.id"
        >{{ project.name }}</b-form-select-option>
      </b-form-select>
    </b-overlay>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
// import VueRouter from 'vue-router'
import router from '@/router'

export default {
  props: {
    size: {
      type: String,
      default() {
        return 'sm'
      },
    },
    extraClass: {
      type: String,
      default() {
        return ''
      },
    },
  },

  setup(_, { root }) {
    // const { isNavigationFailure, NavigationFailureType } = VueRouter
    const store = root.$store
    const currentUser = computed(() => store.state.auth.currentUser)
    const projects = ref([])
    const loading = ref(false)
    const projectId = ref(router.currentRoute.params.projectId)

    function changeProject(id) {
      loading.value = true
      router
        .push({ name: router.currentRoute.name, params: { projectId: id } })
        .then(() => {
          loading.value = false
        })
      const selectedProject = projects.value.find(project => project.id === id)
      store.commit('projects/SET_PROJECT', selectedProject)
    }

    // execute on created
    store
      .dispatch('projects/fetchProjects', {
        customerId: currentUser.value.scoped_roles[0].role_resource_id,
      })
      .then(response => {
        projects.value = response.data
      })

    return {
      projects,
      projectId,
      loading,
      changeProject,
    }
  },
}
</script>

<style lang="scss" scoped>
.bg-custom-grey{
  background-color: #1e1e1e !important;
}
</style>
