<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://1.envato.market/pixinvent_portfolio"
        target="_blank"
      >YoConstruyo</b-link>
      {{ appVersion }}
    </span>

    <span
      class="float-md-right d-none d-md-block text-muted"
    >powered by
      <b-img
        :src="require('@/assets/images/logo/logo_mono.svg')"
        width="20px"
        style="margin-left: 5px; opacity: 0.5"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BLink,
  },
  computed: {
    ...mapGetters('appConfig', ['appVersion']),
  },
}
</script>
